import { CorporateDonationTypeEnum } from "../../enum/corporate-donation-type.enum";
import { FoundUsEnum } from "../../enum/found-us.enum";


export class CorporateProfileModel {
  industry: string = null;
  no_of_employees: number = null;
  about_us: string = null;
  address: string = null;
  website: string = null;
  contact_no: string = null;
  annual_crs_budget: number = null;
  donation_types: CorporateDonationTypeEnum = null;
  charities_categories: string = null;
  matching_ratio: number = null;
  matching_budget: number = null;
  matching_limit: number = null;
  minimum_amount: number = null;
  approval_requirement: boolean = null;
  billing_address: string = null;
  payment_type: string = null;
  billing_contact_name: string = null;
  billing_contact_email: string = null;
  found_us: FoundUsEnum = null;
  additional_info: string = null;

  constructor(obj?: any) {
    if (obj) {
      Object.keys(this).forEach((key) => {
        if (obj.hasOwnProperty(key)) {
          this[key] = obj[key];
        }
      });
    }
  }
}

export class CorporateProfileSignupModel {
  name: string = null;
  email: string = null;
  primary_contact_name: string = null;
  primary_contact_email: string = null;
  primary_contact_phone: string = null;
  industry: string = null;
  no_of_employees: number = null;

  constructor(obj?: any) {
    if (obj) {
      Object.keys(this).forEach((key) => {
        if (obj.hasOwnProperty(key)) {
          this[key] = obj[key];
        }
      });
    }
  }
}